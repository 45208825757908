import React from 'react';

import http from 'http.js';

export function deviceDetecter() {
    let deviceType;
    const ua = navigator.userAgent;
    const isWindowsPhone = /(?:Windows Phone)/.test(ua);
    const isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone;
    const isAndroid = /(?:Android)/.test(ua);
    const isFireFox = /(?:Firefox)/.test(ua);
    const isTablet =
        /(?:iPad|PlayBook)/.test(ua) ||
        (isAndroid && !/(?:Mobile)/.test(ua)) ||
        (isFireFox && /(?:Tablet)/.test(ua));
    const isPhone = /(?:iPhone)/.test(ua) && !isTablet;
    const isPc = !isPhone && !isAndroid && !isSymbian && !isTablet;

    if (isPc) {
        return (deviceType = 'desktop');
    }
    if (isTablet) {
        return (deviceType = 'tablet');
    }
    if (isAndroid || isPhone) {
        return (deviceType = 'mobile');
    }
}

export function classNameHandler(className) {
    return className?.toLowerCase().replace(/[^a-zA-Z]/g, '');
}

export function openLinkInNewTab(url) {
    const a = document.createElement('a');
    a.target = '_blank';
    a.href = url;
    a.click();
}

export function isIntegratedReferralsClient(client) {
    if (!client) {
        return false;
    }
    const lowerClient = client.toLowerCase();
    return (
        lowerClient === 'bp' ||
        lowerClient === 'bp_fhir' ||
        lowerClient === 'md'
    );
}

export function waitForOptimize(callBack) {
    const maxWait = 500;
    const incrementTime = 100;
    let timeElapsed = 0;
    if (window.google_optimize !== undefined) {
        callBack();
        return;
    }
    const intervalId = setInterval(() => {
        if (timeElapsed > maxWait || window.google_optimize !== undefined) {
            callBack();
            clearInterval(intervalId);
        }
        timeElapsed += incrementTime;
    }, incrementTime);
}

/**
 * Determines if html element has overflowing content to the right
 * @param {HTMLElement} element
 * @returns {boolean}
 */
export function elementHasRightOverflow(element) {
    const maxScrollWidth = element.scrollWidth - element.clientWidth;
    return (
        element.scrollLeft !== maxScrollWidth &&
        element.scrollLeft < maxScrollWidth - 1
    );
}

export function flatten(array) {
    return [].concat(...array);
}

export function localStorageGetItem(key) {
    try {
        window.localStorage;
        try {
            return window.localStorage.getItem(key);
        } catch (ex) {
            return '';
        }
    } catch (ex) {
        return '';
    }
}

export function localStorageSetItem(key, val) {
    try {
        window.localStorage;
        try {
            window.localStorage.setItem(key, val);
        } catch (ex) {}
    } catch (ex) {}
}

export function localStorageRemoveItem(key) {
    try {
        window.localStorage;
        try {
            window.localStorage.removeItem(key);
        } catch (ex) {}
    } catch (ex) {}
}

export function sessionStorageGetItem(key) {
    try {
        return window.sessionStorage.getItem(key);
    } catch (ex) {
        return '';
    }
}

export function sessionStorageRemoveItem(key) {
    try {
        window.sessionStorage.removeItem(key);
    } catch (ex) {}
}

export function sessionStorageSetItem(key, val) {
    try {
        window.sessionStorage.setItem(key, val);
    } catch (ex) {}
}

/**
 * Checks if dom storage is available by attempting to set and remove a test item. If this fails than storage is not available.
 * @return {boolean} If storage is available or not
 */
export function storageIsAvailable() {
    try {
        localStorage;
    } catch (error) {
        return false;
    }
    return true;
}

/**
 * Toggles header loading bar visibility.
 * @param {boolean} loading set loading to be true or false
 */
export function toggleLoader(loading) {
    const loadingBar = document.querySelector('.activity-indicator');
    if (loadingBar) {
        if (loadingBar.getAttribute('aria-busy') === 'false' && !loading) {
            loadingBar.classList.remove('activity-indicator');
            void loadingBar.offsetWidth; // trigger reflow to restart animation
            loadingBar.classList.add('activity-indicator');
            loadingBar.setAttribute('aria-busy', 'true');
        } else if (
            loadingBar.getAttribute('aria-busy') === 'true' &&
            loading
        ) {
            loadingBar.setAttribute('aria-busy', 'false');
        }
    }
}

/**
 * Converts all text to a slug format
 * Ex:
 *    -   "this iS a stRing" -> "this-is-a-string"
 *    -   "this 0is a stR1*&%ng" -> "this-is-a-string"
 * @param {string} string
 */
export function slugify(string) {
    return string
        .toLowerCase()
        .replace(/[^a-zA-Z\s]/g, '')
        .replace(/\s/g, '-')
        .replace(/--/g, '-');
}

export function browserCheckForIE11() {
    return document.documentMode === 11;
}

const MONDAY = 'Monday';
const TUESDAY = 'Tuesday';
const WEDNESDAY = 'Wednesday';
const THURSDAY = 'Thursday';
const FRIDAY = 'Friday';
const SATURDAY = 'Saturday';
const SUNDAY = 'Sunday';

export const convertToDayNames = {
    'mon': MONDAY,
    'tue': TUESDAY,
    'wed': WEDNESDAY,
    'thu': THURSDAY,
    'fri': FRIDAY,
    'sat': SATURDAY,
    'sun': SUNDAY,
    'monday': MONDAY,
    'tuesday': TUESDAY,
    'wednesday': WEDNESDAY,
    'thursday': THURSDAY,
    'friday': FRIDAY,
    'saturday': SATURDAY,
    'sunday': SUNDAY,
};
