import cookies from './cookies.js';

function parameterise(data) {
    const params = [];
    for (const k in data) {
        if (!data.hasOwnProperty(k)) {
            continue;
        }
        const v = data[k];
        if (typeof v !== 'undefined') {
            if (Array.isArray(v)) {
                for (const val of v) {
                    params.push(`${k}=${encodeURIComponent(val)}`);
                }
            } else {
                const key = encodeURIComponent(k);
                params.push(`${key}=${encodeURIComponent(v)}`);
            }
        }
    }
    return params.join('&').replace('%20', '+');
}

export default {
    request(opts = {}) {
        if (!opts.url) {
            throw new Error('http.request requires a url parameter.');
        }
        let url = opts.url;
        const method = opts.method ? opts.method.toUpperCase() : 'GET';
        let data = opts.data;
        const handleProgress = opts.handleProgress;
        let contentType = opts.contentType;
        const accept = opts.accept || 'application/json';
        const headers = opts.headers || {};
        const r = new XMLHttpRequest();

        if (opts.timeout) {
            r.timeout = opts.timeout;
        }

        const promise = new Promise(function (resolve, reject) {
            if (data && method === 'GET') {
                let delim = '?';
                // Account for urls with params already set
                if (url.indexOf(delim) !== -1) {
                    delim = '&';
                }
                url += delim + parameterise(data);
                data = undefined;
            }
            r.open(method, url, true);

            if (!(data instanceof FormData) && !contentType) {
                contentType = 'application/json';
                data = JSON.stringify(data);
            }
            if (typeof data === 'object' && !(data instanceof FormData)) {
                data = parameterise(data);
                contentType =
                    'application/x-www-form-urlencoded; charset=UTF-8';
            }

            if (method !== 'GET' && method !== 'HEAD') {
                if (!(/^http:.*/.test(url) || /^https:.*/.test(url))) {
                    // Only send the token to relative URLs i.e. locally.
                    r.setRequestHeader(
                        'X-CSRFToken',
                        cookies.get('csrftoken'),
                    );
                }
            }

            if (handleProgress) {
                r.upload.addEventListener('progress', handleProgress, false);
            }

            if (contentType) {
                headers['Content-Type'] = contentType;
            }
            headers['Accept'] = accept;

            for (const key of Object.keys(headers)) {
                r.setRequestHeader(key, headers[key]);
            }

            r.onload = function () {
                // IE can return 1223 status code instead of 204
                const status = this.status === 1223 ? 204 : this.status;
                const response = this.response || this.responseText;
                const contentType = this.getResponseHeader('Content-Type');
                if (status >= 200 && status < 400) {
                    if (response && contentType === 'application/json') {
                        resolve(JSON.parse(response));
                    } else {
                        resolve(response);
                    }
                } else {
                    reject(
                        new Error(
                            JSON.stringify({
                                contentType,
                                response,
                                status,
                            }),
                        ),
                    );
                    const ajaxError = document.createEvent('Event');
                    ajaxError.initEvent('ajaxError', true, true);
                    ajaxError.status = status;
                    document.dispatchEvent(ajaxError);
                }
            };

            r.onerror = function () {
                reject(this.response);
            };
            r.ontimeout = function () {
                reject(Error('timeout'));
            };
            r.send(data);
        });
        promise.abort = r.abort.bind(r);
        return promise;
    },

    get(opts = {}) {
        opts.method = 'GET';
        return this.request(opts);
    },

    post(opts = {}) {
        opts.method = 'POST';
        return this.request(opts);
    },

    put(opts = {}) {
        opts.method = 'PUT';
        return this.request(opts);
    },

    delete(opts = {}) {
        opts.method = 'DELETE';
        return this.request(opts);
    },

    patch(opts = {}) {
        opts.method = 'PATCH';
        return this.request(opts);
    },

    parameterise,
};
