export default {
    get(name) {
        for (let c of document.cookie.split('; ')) {
            let [k, v] = c.split('=');
            if (k === name) {
                return decodeURIComponent(v);
            }
        }
    },

    set(name, value) {
        document.cookie = name + '=' + encodeURIComponent(value);
    },
};
