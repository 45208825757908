const events = {
    listen(elem, name, handler, options) {
        // Did the user want to listen on multiple elements?
        if (Array.isArray(elem)) {
            for (const e of elem) {
                events.listen(e, name, handler, options);
            }
            return;
        }
        // Did the user ask to listen to a list of events?
        if (Array.isArray(name)) {
            for (const n of name) {
                events.listen(elem, n, handler, options);
            }
            return;
        }
        elem.addEventListener(name, handler, options);
    },

    ready() {
        return new Promise((resolve) => {
            if (
                document.readyState === 'complete' ||
                (document.readyState !== 'loading' &&
                    !document.documentElement.doScroll)
            ) {
                setTimeout(resolve);
            } else {
                document.addEventListener('DOMContentLoaded', resolve);
            }
        });
    },

    trigger(elem, name) {
        if (document.createEvent) {
            const e = document.createEvent('HTMLEvents');
            e.initEvent(name, true, false);
            elem.dispatchEvent(e);
        } else {
            elem.fireEvent(`on${name}`);
        }
    },

    unlisten(elem, name, handler) {
        // Did user want to unlisten on multiple elements?
        if (Array.isArray(elem)) {
            for (const e of elem) {
                events.unlisten(e, name, handler);
            }
            return;
        }
        // Did the user ask to unlisten to a list of events?
        if (Array.isArray(name)) {
            for (const n of name) {
                events.unlisten(elem, n, handler);
            }
            return;
        }
        elem.removeEventListener(name, handler);
    },
};

export default events;
